<template>
  <div class="ma-4">
    <v-row>
      <v-col cols="12" md="10" offset-md="1" lg="8" offset-lg="2">
        <v-card>
          <v-card-title>Scots Portal 2.0</v-card-title>
          <v-card-text>
            <div>Welcome to the new Scots Portal.</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="comp in components" :key="comp" cols="12" md="6" :lg="components.length === 4 ? '6' : '4'" xl="3">
        <component v-bind:is="comp" class="mb-2"></component>
      </v-col>
      <!-- <template v-if="breakpoint === 'xs'">
        <v-col cols="12">
          <banner-links></banner-links>
        </v-col>
        <v-col cols="12">
          <student-links></student-links>
        </v-col>
        <v-col cols="12">
          <helpful-links class="mb-2"></helpful-links>
        </v-col>
        <v-col cols="12">
          <workstudy-widget mini></workstudy-widget>
        </v-col>
      </template>
      <template v-else-if="breakpoint === 'sm'">
        <v-col cols="6">
          <banner-links class="mb-2"></banner-links>
        </v-col>
        <v-col cols="6">
          <student-links></student-links>
        </v-col>
        <v-col cols="12">
          <helpful-links class="mb-2"></helpful-links>
        </v-col>
        <v-col cols="12">
          <workstudy-widget mini></workstudy-widget>
        </v-col>
      </template>
      <template v-else-if="breakpoint === 'md'">
        <v-col cols="6">
          <banner-links class="mb-2"></banner-links>
          <helpful-links class="mb-2"></helpful-links>
        </v-col>
        <v-col cols="6">
          <student-links class="mb-2"></student-links>
          <workstudy-widget mini class="mb-2"></workstudy-widget>
        </v-col>
      </template>
      <template v-else-if="breakpoint === 'lg' || breakpoint === 'xl'">
        <v-col cols="4">
          <banner-links class="mb-2"></banner-links>
        </v-col>
        <v-col cols="4">
          <student-links class="mb-2"></student-links>
        </v-col>
        <v-col cols="4">
          <workstudy-widget mini class="mb-2"></workstudy-widget>
        </v-col>
      </template> -->
    </v-row>
  </div>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { inArray } from '@/helpers/functions'

export default {
  components: {
    BannerLinks: () => import('@/components/dashboard/Banner'),
    StudentLinks: () => import('@/components/dashboard/Student'),
    WorkstudyWidget: () => import('@/components/work-study/widget'),
    HelpfulLinks: () => import('@/components/dashboard/HelpfulLinks')
  },
  setup (props, { root, store }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const isStudent = computed(() => {
      return inArray('Students', user.value.roles)
    })

    const breakpoint = computed(() => root.$vuetify.breakpoint.name)

    const placements = ref([])
    onMounted(async () => {
      let pidm = user.value.pidm
      // if (pidm === '2552') pidm = '1248667'
      placements.value = await root.$store.dispatch('work-study/loadPlacements', { pidm })
    })

    const components = computed(() => {
      const arr = ['BannerLinks', 'StudentLinks', 'HelpfulLinks']
      if (placements.value.length > 0) arr.push('WorkstudyWidget')
      return arr
    })

    return {
      isStudent,
      breakpoint,
      placements,
      components
    }
  }
}
</script>
